// 訂單活動名稱
export const orderNames = [
    { value: "共享美食" },
    { value: "歡樂唱歌" },
    { value: "欣賞電影" },
    { value: "小酌同樂" },
    { value: "去看書" },
    { value: "走走晃晃" },
    { value: "閒談聊天" },
    { value: "看球賽" },
    { value: "社交聚會" },
    { value: "商業活動配合" },
];
