<template>
    <div class="p-5 bg-white rounded-lg shadow-lg">
        <div class="text-xl">
            {{ route.params.type === "create" ? "新增" : "更新" }}即刻快閃範本單
        </div>
        <el-form ref="formRefDom" :rules="rules" :model="form" class="mt-10">
            <div class="grid grid-cols-2 gap-4">
                <el-form-item prop="name">
                    <label class="block mb-2 text-base">活動名稱</label>
                    <el-autocomplete
                        v-model="form.name"
                        class="w-full"
                        :fetch-suggestions="querySearch"
                        placeholder="自行填入/選擇您的活動"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item prop="provider_required">
                    <label class="block mb-2 text-base">活動需求人數</label>
                    <el-select v-model="form.provider_required" class="w-full">
                        <el-option
                            v-for="item in 10"
                            :key="item"
                            :lable="item"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="grid grid-cols-2 gap-4">
                <el-form-item prop="hourly_pay">
                    <label class="block mb-2 text-base">每小時活動出席費</label>
                    <el-input v-model.number="form.hourly_pay"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="flex justify-center my-10">
            <button
                class="min-w-[150px] py-2 mr-5 text-black border border-black rounded-lg"
                @click.prevent="
                    router.push({
                        name: 'rightnow_activity_marquee',
                        params: { type: 'vorder' },
                    })
                "
            >
                返回列表
            </button>
            <button
                class="min-w-[150px] py-2 text-white bg-red-500 rounded-lg"
                @click.prevent="onSubmit"
            >
                {{ route.params.type === "create" ? "新增" : "儲存變更" }}
            </button>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 導入 三位數加上逗號演算法
import { formatCurrency } from "@/service/anyService";
// 即刻快閃預訂行程名稱範本
import { orderNames } from "@/config/rightNowActivityConfig";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const router = useRouter();
  const route = useRoute();
  // 表單 dom
  const formRefDom = ref(null);
  // 表單資料
  const form = ref({});
  // 表單驗證規則
  const rules = ref({
    name: [{
      required: true,
      message: "活動名稱為必填",
      trigger: "change"
    }, {
      max: 10,
      message: "活動名稱最多為10個字",
      trigger: "blur"
    }],
    provider_required: [{
      required: true,
      message: "活動需求人數為必填",
      trigger: "blur"
    }, {
      validator: formProviderRequired,
      trigger: "blur"
    }],
    hourly_pay: [{
      required: true,
      message: "每小時活動出席費為必填",
      trigger: "blur"
    }, {
      validator: formPriceCheck,
      trigger: "blur"
    }]
  });

  // 活動需求人數 判斷不可大於10人
  function formProviderRequired(rule, value, callback) {
    if (value > 10) {
      return callback(new Error(`不可大於10人`));
    }
    return callback();
  }

  // 判斷價格是否小於 2000 或大於 最高金額 999999
  function formPriceCheck(rule, value, callback) {
    // 判斷數字是否小於最小儲值額度限制
    if (value < parseInt(process.env.VUE_APP_ACTIVITY_MIN_PRICE)) {
      return callback(new Error(`請輸入大於 ${formatCurrency(process.env.VUE_APP_ACTIVITY_MIN_PRICE)} 以上的數值`));
    }
    // 判斷數字是否大於最大儲值額度限制
    if (value > process.env.VUE_APP_POINT_MAX_LIMIT) {
      return callback(new Error(`請輸小於 ${formatCurrency(process.env.VUE_APP_POINT_MAX_LIMIT)} 以上的數值`));
    }
    return callback();
  }

  /**
   * 表單發送
   * @param { type String(字串) } formName 傳送表單名稱
   * @example ref="form"
   */
  async function onSubmit(formName) {
    try {
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await formRefDom.value.validate();
      await createOrUpdate();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "資訊未填寫正確"
      });
    }
  }

  /**
   * 新增或更新
   */
  async function createOrUpdate() {
    try {
      await proxy.$api.CreateOrUpdateRightNowActivityMarqueeDetailApi(route.params.type === "create" ? route.params.id : form.value.id, form.value);
      proxy.$message({
        type: "success",
        message: `${route.params.type === "create" ? "新增" : "更新"}成功`
      });
      router.push({
        name: "rightnow_activity_marquee",
        parmas: {
          type: "vorder"
        }
      });
      form.value = {};
    } catch (err) {
      proxy.$message({
        type: "error",
        message: `${route.params.type === "create" ? "新增" : "更新"}失敗`
      });
    }
  }

  /**
   * 取得資料
   */
  async function getData() {
    try {
      const {
        data
      } = await proxy.$api.GetRightNowActivityMarqueeDetailApi(route.params.id);
      form.value = {
        id: data.demand_id,
        name: data.name,
        hourly_pay: data.hourly_pay,
        provider_required: data.provider_required
      };
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    }
  }

  // 預設活動名稱資料
  const defaultNames = ref(orderNames);
  // 自動完成輸入框搜尋條件
  function querySearch(queryString, cb) {
    var results = queryString ? defaultNames.value.filter(createFilter(queryString)) : defaultNames.value;
    // 调用 callback 返回建议列表的数据
    cb(results);
  }
  // 自動完成輸入框過濾結果
  function createFilter(queryString) {
    return defaultNames => {
      return defaultNames.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    };
  }
  onMounted(async () => {
    if (route.params.type === "update") {
      await getData();
    }
  });
  onActivated(async () => {
    form.value = {};
    if (route.params.type === "update") {
      await getData();
    }
  });
  return {
    router,
    route,
    formRefDom,
    form,
    rules,
    onSubmit,
    querySearch
  };
};
export default __sfc_main;
</script>
